import { ORIGIN_COOKIE } from 'components/Payment/constants'
import { parseCookies } from 'nookies'

let cookies = parseCookies() || {}

/*
  Should we ditch cookies as the storage mechanism? Could just as easily use context or session storage
*/
export const isEonxEnvironment = () => {
  const origin = (cookies[ORIGIN_COOKIE] || '').toLowerCase()
  const isOriginEonx = origin === 'eonx'
  return isOriginEonx
}

export const updateEonxEnvironment = () => {
  cookies = parseCookies() || {}
}
