import * as amplitude from '@amplitude/analytics-browser'
import { BaseEvent } from '@amplitude/analytics-types'
import { isServer } from '@mr-yum/frontend-core/dist/support/env'
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react'
import {
  FLAG_ALLOW_POSITIONING_INFO_BLOCKS,
  FLAG_NEW_REDEEM_POINTS_VALUE,
  FLAG_POINTS_TO_USE_POLLING,
  FLAG_REWARDS_BALANCE,
  FLAG_REWARDS_PRICE,
} from 'common/constants'
import { isEonxEnvironment } from 'components/EonX/utils'
import { config } from 'lib/config'
import { Partner, useCurrentUserQuery } from 'lib/gql'
import { useCallback, useEffect } from 'react'

const AMPLITUDE_API_KEY = config.amplitudeKey

let identifySet = false

if (!isServer) {
  amplitude.init(AMPLITUDE_API_KEY, {
    defaultTracking: false,
  })
}

export const useAmplitude = () => {
  const { flagsReady } = useFlagsStatus()

  const rewardsPrice = useFlag(FLAG_REWARDS_PRICE)
  const rewardsBalance = useFlag(FLAG_REWARDS_BALANCE)
  const allowPositioningOfInfoBlocks = useFlag(
    FLAG_ALLOW_POSITIONING_INFO_BLOCKS,
  )
  const newRedeemPointsValue = useFlag(FLAG_NEW_REDEEM_POINTS_VALUE)
  const pointsToUseShouldPoll = useFlag(FLAG_POINTS_TO_USE_POLLING)

  const isEonx = isEonxEnvironment()

  const [{ data }] = useCurrentUserQuery({
    pause: isServer || process.env.NODE_ENV === 'test',
  })
  const userId = data?.currentUser?.id || undefined

  useEffect(() => {
    if (userId !== amplitude.getUserId()) {
      amplitude.setUserId(userId)
    }
  }, [userId])

  useEffect(() => {
    if (flagsReady && !identifySet) {
      const identifyEvent = new amplitude.Identify()
      identifyEvent.set('feature flags', {
        rewardsPrice,
        rewardsBalance,
        allowPositioningOfInfoBlocks,
        newRedeemPointsValue,
        pointsToUseShouldPoll,
      })
      identifyEvent.set(
        'partnerApp',
        isEonx ? Partner.Eonx : Partner.Unspecified,
      )
      amplitude.identify(identifyEvent)
      identifySet = true
    }
  }, [
    flagsReady,
    rewardsBalance,
    rewardsPrice,
    allowPositioningOfInfoBlocks,
    newRedeemPointsValue,
    pointsToUseShouldPoll,
    isEonx,
  ])

  const logEvent = useCallback(
    (
      eventInput: string | BaseEvent,
      eventProperties?: Record<string, any> | undefined,
    ) => {
      amplitude.track(eventInput, eventProperties)
    },
    [],
  )

  return { logEvent }
}
