export function stripMobile(mobile?: string) {
  if (!mobile) {
    return undefined
  }

  const stripped = mobile.replace(/\D/g, '')

  return stripped
}

export async function hashPiiCookie(
  email?: string,
  mobile?: string,
  name?: string,
) {
  // hash email
  const utf8Email = new TextEncoder().encode(email)
  const hashBufferEmail = await crypto.subtle.digest('SHA-256', utf8Email)
  const hashArrayEmail = Array.from(new Uint8Array(hashBufferEmail))
  const hashHexEmail = hashArrayEmail
    .map((bytes) => bytes.toString(16).padStart(2, '0'))
    .join('')

  // hash mobile
  const strippedMobile = stripMobile(mobile)
  const utf8Mobile = new TextEncoder().encode(strippedMobile)
  const hashBufferMobile = await crypto.subtle.digest('SHA-256', utf8Mobile)
  const hashArrayMobile = Array.from(new Uint8Array(hashBufferMobile))
  const hashHexMobile = hashArrayMobile
    .map((bytes) => bytes.toString(16).padStart(2, '0'))
    .join('')

  // hash name
  const utf8Name = new TextEncoder().encode(name)
  const hashBufferName = await crypto.subtle.digest('SHA-256', utf8Name)
  const hashArrayName = Array.from(new Uint8Array(hashBufferName))
  const hashHexName = hashArrayName
    .map((bytes) => bytes.toString(16).padStart(2, '0'))
    .join('')

  const hashedIds = {
    hash_email: hashHexEmail,
    hash_mobile: hashHexMobile,
    hash_name: hashHexName,
  }
  const hashedIdString = JSON.stringify(hashedIds)

  document.cookie = `hashed_id=${hashedIdString}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`
}
