import { isServer } from '@mr-yum/frontend-core/dist/support/env'
import { useCurrentUserQuery } from 'lib/gql'
import { hashPiiCookie } from 'utils/hashPiiCookie'

export const useCurrentUser = () => {
  const [{ data, fetching: fetchingCurrentUser, stale: staleCurrentUser }] =
    useCurrentUserQuery({
      pause: isServer,
      requestPolicy: 'cache-and-network',
    })
  const currentUser = data?.currentUser

  if (currentUser) {
    void hashPiiCookie(
      currentUser.email ?? undefined,
      currentUser.mobile ?? undefined,
      currentUser.name ?? undefined,
    )
  }

  return {
    fetchingCurrentUser,
    staleCurrentUser,
    currentUser,
  }
}
