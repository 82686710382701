import { OrderingType } from 'lib/gql'
import { getOrderingTypeFromSlug, OrderingTypeSlug } from 'lib/routes'
import { useRouter } from 'next/router'
import React, { createContext, ReactNode, useContext } from 'react'
import {
  getNestedVenueSlug,
  getVenueAndOrderingTypeFromQuery,
} from 'utils/venue'

export interface OrderingTypeProps {
  orderingTypeSlug: OrderingTypeSlug
  orderingType: OrderingType
}

interface VenueProps {
  venueSlug: string
}

export const OrderingTypeContext = createContext<OrderingTypeProps | null>(null)

export const VenueContext = createContext<VenueProps | null>(null)

export const useVenueContext = () => {
  const value = useContext(VenueContext)

  if (!value) {
    throw new Error('Venue Context must be Provided')
  }
  return value
}

/*
  To be used when we know venueSlug is in the URL.
*/
export const VenueController = ({ children }: { children: ReactNode }) => {
  const { query } = useRouter()
  const venueSlug = getNestedVenueSlug(query) ?? ''

  return (
    <VenueContext.Provider
      value={{
        venueSlug,
      }}
    >
      {children}
    </VenueContext.Provider>
  )
}

export const useOrderingTypeContext = () => {
  const value = useContext(OrderingTypeContext)

  if (!value) {
    throw new Error('OrderingType Context must be Provided')
  }
  return value
}

/*
  To be used when we know these values are in the URL.
*/
export const OrderingTypeController = ({
  children,
}: {
  children:
    | ReactNode
    | (({
        orderingType,
        orderingTypeSlug,
      }: {
        orderingType: OrderingType
        orderingTypeSlug: OrderingTypeSlug
      }) => ReactNode)
}) => {
  const { query } = useRouter()
  const { orderingTypeSlug } = getVenueAndOrderingTypeFromQuery(query)
  const orderingType = getOrderingTypeFromSlug(orderingTypeSlug)

  return (
    <OrderingTypeContext.Provider
      value={{
        orderingTypeSlug,
        orderingType,
      }}
    >
      {typeof children === 'function'
        ? children({ orderingType, orderingTypeSlug })
        : children}
    </OrderingTypeContext.Provider>
  )
}
